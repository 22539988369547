<template>
  <div class="vld-parent">
    <loading :active="isLoading"
    :can-cancel="true"
    :loader="bars"
    :opacity='0.5'
    :on-cancel="onCancel"
    :is-full-page="fullPage"><img src="/image/loading.svg"></loading>
  </div>
  <main class="layout-main">
    <div class="inner">
      <div class="container-wrapper">
        <div class="layout-title">
          <h1 class="heading-page"><img src="@/assets/image/title/news.svg" alt="Works"><span class="sub">お知らせ</span></h1>
          <a @click="$router.back()" class="prev button-prev">PREV</a>
        </div>
      </div>

      <section class="">
        <div class="container-wrapper container-pad -nopaddingtop -narrow">

            <div>
              <h2 class="heading-copy" v-if="item.data.title">{{item.data.title.rendered}}</h2>
              <div class="meta-date"><time v-if="item.data.modified">{{ moment(item.data.modified ) }}</time></div>
              <div class="container-body" v-if="item.data.content" v-html="item.data.content.rendered"></div>
            </div>


        </div>
      </section>
      </div>
  </main>

</template>

<script>
import { onMounted, onUpdated, onUnmounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import axios from "axios";
import moment from 'moment'
import Loading from 'vue-loading-overlay';
require( 'vue-loading-overlay/dist/vue-loading.css')

export default {
  components: {
    Loading
  },
  setup() {
    const API_HOST = process.env.VUE_APP_APIRESTHOST;
    const isLoading = ref(false);
    const route = useRoute()
    const item = reactive({
      data:[],
    })

    const  getItem = async () => {
      isLoading.value = true
      await axios.get('//' + API_HOST + '/c/wp-json/wp/v2/posts/' + route.params.id)
      .then( response => {
        //posts.value = response.data
        item.data = response.data
        setTimeout(() => {
          isLoading.value = false
        }, 300)
      }).catch(
        error => console.log(error)
      )
    }

    onMounted(() => {
      getItem()
    })
    onUpdated(() => {
      console.log('updated!')
    })
    onUnmounted(() => {
      console.log('unmounted!')
    })
    return{
      item,
      isLoading
    }
  },
  methods: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD')
    }
  },

}

</script>